import React, { useState } from "react";
// API helper
import { emailChangeHandler, submitHandler } from '../../../helper/utilHelper';
// Html Parser
import parse from "html-react-parser";
import Link from "next/link";
import Image from "next/image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// scss
import styles from "./adsBanner.module.scss";

function AdsBanner(props) {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailInValidMsg, setEmailInValidMsg] = useState('');

  const {currLocale, linkLocale} = props;

  const ctaSection = props.serviceContent && props.serviceContent.cta_section;

  return <>
    {props.adsbanner ?
      <div className={`${styles.offer} ${styles.pt0}`}>
        <section className={`${styles.forNew} ${styles.orderBanner}`}>
          <Container>
            <div className="text-center">
              <div className={`${styles.title} ${styles.sectionTitle}`}>{ctaSection && ctaSection.title ? parse(ctaSection.title) : ''}</div>
              <div className={styles.desc}>{ctaSection && ctaSection.content ? parse(ctaSection.content) : ''}</div>
              <div className="d-flex justify-content-center">
                <Link
                  href={'/order'}
                  locale={linkLocale}
                  prefetch={false}
                  id="orderEssay"
                  className={`btn secondary-btn inline-button-click`}>
                  Order an Essay</Link>
                <Link
                  href={"/price"}
                  locale={linkLocale}
                  prefetch={false}
                  className={`${styles.btn} btn outlineSecondaryBtn`}>
                  Check Prices</Link>
              </div>
            </div>
          </Container>
        </section>
      </div>
      :
      <div id="offer" className={styles.offer}>
        <div className={styles.forNew}>
          <Container>
            <Row className="align-items-center">
              <Col md={4}>
                <div className={styles.banner}>
                  <Image src={props.src} alt="banner" title="Banner" width="148" height="148" />
                  <div className={styles.content}>15% <span>off</span></div>
                </div>
                <div className={styles.text}>on your first checkout</div>
              </Col>
              <Col md={8}>
                <div className="text-center">
                  <div className={styles.title}>New to CheapestEssay Sign up &amp; Save</div>
                </div>
                <div className={styles.inputWrpper}>
                  <form className={`d-flex ${styles.inputGroup}`} method="post"
                    onSubmit={(event) => submitHandler(event, setEmailInValidMsg, enteredEmail, currLocale)}>
                    <div className={styles.emailInput}>
                      <input
                        type="text"
                        placeholder="Your Email"
                        className={styles.formControl}
                        value={enteredEmail}
                        onChange={(e) => emailChangeHandler(e, setEnteredEmail)}
                      // onBlur={validateEmailHandler}
                      />
                      <p className="errorMsg" title={emailInValidMsg}>{emailInValidMsg}</p>
                    </div>
                    <button type="submit" id="getDiscount"
                      className={`${styles.btn} btn secondary-btn inline-button-click ${props.btnclassName}`}>Get a Discount</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    }
  </>;
}

export default AdsBanner;